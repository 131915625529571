var testimonials = [
  {
    content:
      "Manageo a été la seule solution à répondre à nos besoins spécifiques et parfois complexes tout en assurant une utilisation simple et intuitive. Aujourd’hui toutes nos opérations sont actuellement intégrées et gérées sur Manageo, de la prospection, à la livraison en passant par les achats, le stock et la production. Au delà du paramétrage, l’équipe Manageo a participé à l’optimisation de nos processus métiers en tirant pleinement profit des capacités de la solution. Nous en sommes extrêmement satisfaits.",
    author: "Tayeb H.",
    company: "Desco",
  },
  {
    content:
      "Nous utilisons Manageo depuis septembre 2017 et nous en sommes très satisfaits. Au delà de son aspect très intuitif et facile à utiliser, Manageo a su mettre à nos dispositions toutes les ressources humaines et techniques nécessaires, pour  faire évoluer la plateforme selon le schéma que l'on s'est fixé. Le chantier est toujours en cours et nous espérons que notre collaboration continuera à être aussi productive...... Merci à toute l'équipe",
    author: "Selma B.",
    company: "Bonzai communication ",
  },
  {
    content:
      "Le choix d’opter pour le logiciel Manageo s’est fait naturellement, une évolution qui nous a fait gagner en temps et en qualité en proposant un service innovant, il correspond parfaitement à notre organisation et nos besoins. Il est simple, intuitif, fonctionnel et la prise en main s’effectue rapidement, depuis, nous sommes beaucoup plus performants. Concernant le SAV, Manageo est très réactif, on est vite dépanné et l’accueil est très satisfaisant. Je n’hésiterai pas à le recommander",
    author: "Mohamed K.",
    company: "Groupe Gaia Medias",
  },
  {
    content:
      "Dès l'adoption de la solution Manageo, nous avons pu économiser énormément de temps sur les processus facturation, prospection et gestion de stock. L'application est très intuitive et pratique et l'équipe est très réactive et toujours à l'écoute. Bravo à l'équipe :)",
    author: "Laila B.",
    company: "PLAY ACADEMY",
  },
  {
    content:
      "J’ai commencé l’utilisation de Manageo il y a à peu près de 2 mois. Je peux déjà confirmer que c’est un outil convivial et fluide. Je suis abonné aussi bien à la version de base qu’au module prospection. Ce dernier est bien conçu et permet de gagner en efficacité. Je recommande vivement son utilisation.",
    author: "Amine AK",
    company: "Value Expert Group ",
  },
  {
    content:
      "La simplicité et l’efficacité sont les deux points forts du logiciel MANAGEO. Etant une entreprise de service, l’équipe BEWEB a vite pu adapter la plateforme à nos besoins afin de nous simplifier la gestion des taches administratives quotidiennes et nous permettre de nous concentrer sur notre métier de base. Nous ne pouvons qu’être satisfait de notre choix porté sur ce logiciel simple et innovant et de son équipe réactive et efficiente.",
    author: "Hicham C.",
    company: "H&H Consulting Law Firm",
  },
];

export default testimonials;

var countries = [
  {
    meta_title: "Logiciel de facturation pour TPE au Maroc",
    name: "Maroc",
    flag: "ma",
    currency: "MAD",
    analytics: "",
  },
  {
    meta_title: "Logiciel de facturation pour TPE en Côte d'ivoire",
    name: "Côte d'ivoire",
    flag: "ci",
    currency: "XOF",
    analytics: "",
  },
  {
    meta_title: "Logiciel de facturation pour TPE en Tunisie",
    name: "Tunisie",
    flag: "tn",
    currency: "TND",
    analytics: "",
  },
  {
    meta_title: "Logiciel de facturation pour TPE au Sénégal",
    name: "Sénégal",
    flag: "sn",
    currency: "XOF",
    analytics: "",
  },
  {
    meta_title: "Logiciel de facturation pour TPE au Togo",
    name: "Togo",
    flag: "tg",
    currency: "XOF",
    analytics: "",
  },
  {
    meta_title: "Logiciel de facturation pour TPE au Gabon",
    name: "Gabon",
    flag: "ga",
    currency: "XOF",
    analytics: "",
  },
];

export default countries;

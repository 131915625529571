<template lang="html">
  <div class="page">
    <my-header></my-header>

    <Separator />

    <section class="page">
      <div class="container">
        <div class="row">
          <div class="col-md-7 pr-4">
        
            <h2>Centres <strong>d'affaires</strong></h2>
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/B3j93Kb8EJg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
     
          </div>
          <div class="col-md-5" id="form">
            <DemoRequest country="MA" utm_source="amca"></DemoRequest>
          </div>
        </div>
      </div>

      <Features />

      <Pricing currency="MAD" />

      <Numbers />

      <Testimonials />

      <Arguments />

      <Faq />
      
      <Separator />

    </section>
    <my-footer></my-footer>
  </div>
</template>

<script lang="js">

import MyHeader from "../../components/Header";
import Features from "../../components/Features";
import DemoRequest from "./DemoRequest";
import Pricing from "../../components/Pricing";
import Testimonials from "../../components/Testimonials";
import Numbers from "../../components/Numbers";
import Separator from "../../components/Separator.vue";
import MyFooter from "../../components/Footer";
import Arguments from "../../components/Arguments";
import Faq from "../../components/Faq";

  export default  {
    name: 'page',
    components: {
      MyHeader,
      MyFooter,
      Features,
      DemoRequest,
      Pricing,
      Testimonials,
      Separator,
      Numbers,
      Faq,
      Arguments
    },
    props: [],
    mounted () {

    },
    data () {
      return {

      }
    },
}
</script>

<style scoped lang="css">
.page {
}
</style>

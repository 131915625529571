<template lang="html">
  <section class="pricing">
    <b-container class="text-center">
      <h2>
        Un prix <strong>imbattable</strong> 
        <span v-if="discount"> - <strong>{{ discount }} % </strong> de remise</span>
      </h2>
      <p>
        Manageo s’adapte à votre secteur d’activité. Que vous soyez dans le
        secteur des services, du négoce, de la distribution ou encore une agence
        media, Manageo s’adaptera à vos process métier en vous offrant des modules
        testés et approuvés par des centaines d’entreprises comme la votre.
      </p>
<!-- 
      <b-button-group class="m-4">
        <b-button
          style=""
          v-for="(value, index) in currencies"
          :key="index"
          variant="success"
          v-on:click="setCurrency(value)"
          :class="(currency == value) ? 'active' : ''"
        >
         <span :class="(currency == value) ? 'active' : ''">
          {{ value }}
          </span>
        </b-button>
      </b-button-group> -->
    </b-container>

    <b-container>
      <b-row>
        <b-col lg="3" v-for="(plan, index) in plans" :key="index">
          <b-card :title="plan.name">
            <ul>
              <li class="table-prices">
                <div class="price-in-table">
                  <span class="price" v-if="!plan.price[currency]">
                    Gratuit
                  </span>
                  <span class="price" v-else>
                      <span v-if="discount">
                        {{ plan.price[currency] * (100 - discount) / 100 }}
                      </span>
                      <span v-else>
                        {{ plan.price[currency] }}
                      </span>
                    </span>
                  <span class="value" v-if="plan.price[currency]">{{
                    currency
                  }}</span>
                  <div>
                    <span class="discount text-danger" v-if="discount > 0">
                      <strike><strong>{{ plan.price[currency] }} </strong></strike>
                    </span>
                  </div>
                  <span class="mark" v-if="plan.price[currency]"
                    >HT par an</span
                  >
                  <span class="mark" v-if="plan.price[currency] == 0"
                    >&nbsp;
                  </span>
                </div>
              </li>
              <li class="table-content">
                <p></p>
                <ul>
                  <li v-for="(feature, index) in plan.features" :key="index">
                    {{ feature }}
                  </li>
                </ul>
                <p></p>
              </li>
              <!-- <li class="price-button text-center mb-3">
                <b-button
                  type="submit"
                  size="lg"
                  variant="success"
                  href="#form"
                >
                  <span class="btn-text">M'inscrire</span>
                  <span class="btn-text-icon"></span
                ></b-button>
              </li> -->
            </ul>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="mt-5">
      <b-row>
        <b-col lg="6 text-left">
          <h3>Besoin de plus d’utilisateurs ?</h3>
          <p>
            Vous pouvez ajouter autant d’utilisateurs que vous le souhaitez à un
            coût de
            <strong
              >{{ user[currency] }} {{ currency }} HT/mois/utilisateur</strong
            >.
          </p>
          <h3>Formation</h3>
          <p>
            Une formation en ligne est offerte, elle dure entre une et trois
            heures. Elle vous permettra d’être opérationnel sur la
            plateforme.<br />
            Si vous souhaitez avoir une formation présentielle, cette dernière
            sera facturée à
            <strong>{{ training[currency] }} {{ currency }} HT</strong>
            la demi journée.
          </p>
          <h3>Support technique</h3>
          <p>
            Vous disposez d’une assistance technique
            <strong>gratuite</strong> par téléphone et email pendant toute la
            durée de votre abonnement.
          </p>
        </b-col>
        <b-col lg="6">
          <img src="@/assets/img/mockup.png" width="100%" border="0" />
        </b-col>
      </b-row>
    </b-container>

    <div class="mb-4"></div>
  </section>
</template>

<script lang="js">

import plans from "../data/plans" ;

export default  {
  name: 'pricing',
  props : ['currency', 'discount'] ,
  data() {
    return {
      currencies: ["MAD", "XOF", "USD"],
      training: {
          MAD: 2000,
          XOF: 100000,
          USD: 150
      },
      user: {
          MAD: 200,
          XOF: 12000,
          USD: 20
      },
      plans: plans
    }
  },
  mounted() {
    this.plans.shift() ;
  },
  methods: {
      setCurrency: function(currency) {
          this.currency = currency;
      }
  }
}
</script>

<style scoped lang="css">
.pricing {
}
h3 {
  text-align: left;
}
span.active {
  font-weight: bold;
}
.card {
  margin-bottom: 10px;
  padding: 0px;
  margin: 0px;
}
.card-title {
  padding: 28px 10px;
  text-align: center;
  position: relative;
  background-color: #159eee;
  border: 1px solid #e9e9e9;
  color: #fff;
  border-bottom-color: rgb(233, 233, 233);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom: 0;
}
ul li.table-prices {
  padding: 20px 5px;
  background-color: #fff;
  text-align: center;
  margin: 0;
  padding: 14px;
}
ul li.table-prices .price {
  font-size: 42px;
  line-height: 42px;
  vertical-align: middle;
  color: #222;
  font-weight: 600;
}
ul li.table-prices .mark {
  display: block;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #989898;
  text-transform: capitalize;
  background-color: #fff;
}
ul li.table-prices .value {
  font-size: 24px;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0px;
}
.card .card-body ul {
  margin: 0;
  padding: 0;
}
.card .card-body ul li {
  list-style: none;
  margin: 0;
  padding: 0;
  list-style: none;
}
.card .card-body ul li.table-content ul li:nth-child(2n + 1) {
  background-color: #f8f8f8;
}
.card .card-body ul li.table-content ul li {
  text-align: center;
  margin: 0;
  padding: 14px;
}
</style>

<template lang="html">
  <section class="footer">
    <div class="container">
      <div class="text-center">
        <div class="m-2 mb-4"> 
          Florida Centre Park, Lot 5, Bureau n°30, Sidi Maarouf <br/>
          Casablanca<br/>
          <span class="pi pi-phone"></span> <a href="tel:+212522215830">(+212) 5 22 21 58 30</a><br/>
          <span class="pi pi-mobile"></span> <a href="tel:+212661988460">(+212) 6 61 98 84 60</a><br/>
          <span class="pi pi-clock"></span> Lundi – Vendredi: 09:00 – 18:00<br/>
          Samedi – Dimanche: Fermé<br/>
        </div>
        <div class="m-2"> 
          <img
            :title="country.name"
            :src="country.img"
            v-for="country in countries"
            :key="country.flag"
          />
        </div>
        <div class="m-2"> 
          Copyright 2020 © <a :href="url">Manageo.io</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">

import countries from "../data/countries" ;

  export default  {
    props: [],
    data () {
      return {
        url : null,
        countries : countries
      }
    },
    created() {
      var utm_source = this.$route.query.utm_source ? this.$route.query.utm_source : "" ; 
      var utm_medium = this.$route.query.utm_medium ? this.$route.query.utm_medium : "" ; 
      var utm_campaign = this.$route.query.utm_campaign ? this.$route.query.utm_campaign : "" ; 
      
      this.url = "https://manageo.io/?utm_source="+utm_source +"&utm_medium="+utm_medium+"&utm_campaign="+utm_campaign

      this.countries.forEach((country, index) =>  {
        this.countries[index].img = require("@/assets/img/flags/" + country.flag + ".png") ; 
      }) 
      
    }
}
</script>

<style scoped lang="css">
.footer {
  margin: 30px;
  line-height: 25px;
}
.footer img {
  margin: 0px 5px 20px 0px;
  width: 30px;
}
</style>

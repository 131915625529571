<template lang="html">
  <section class="register">
    <!-- Partner ID {{partnerid}} -->
    <a name="form"></a>
    <div v-if="registerForm">
      <b-form @submit.prevent="register">
        <b-form-group
          label-cols-lg="4"
          content-cols-lg="8"
          label="Entreprise *"
        >
          <b-form-input
            placeholder="Raison Sociale"
            v-model="$v.organization.name.$model"
            @input="slugify('name')"
          ></b-form-input>
          <small
            class="form-text text-danger"
            v-if="$v.organization.name.$dirty && !$v.organization.name.required"
          >
            Ce champs est obligatoire
          </small>
        </b-form-group>

        <b-form-group
          label-cols-lg="4"
          content-cols-lg="8"
          label="Page de connexion *"
        >
          <b-input-group
            prepend="https://"
            append=".manageo.io"
            class="mb-2 mr-sm-2 mb-sm-0"
          >
            <b-form-input
              placeholder="raisonsociale"
              v-model="organization.host"
              @input="slugify('host')"
            ></b-form-input>
          </b-input-group>
          <small class="form-text text-success" v-if="success">
            <span v-html="success"></span>
          </small>
          <small class="form-text text-danger" v-if="error">
            <span v-html="error"></span
          ></small>
        </b-form-group>

        <b-form-group label-cols-lg="4" content-cols-lg="8" label="Prénom *">
          <b-form-input
            placeholder="Prénom"
            v-model="organization.firstname"
          ></b-form-input>
          <small
            class="form-text text-danger"
            v-if="
              $v.organization.firstname.$dirty &&
                !$v.organization.firstname.required
            "
          >
            Ce champs est obligatoire
          </small>
        </b-form-group>

        <b-form-group label-cols-lg="4" content-cols-lg="8" label="Nom *">
          <b-form-input
            placeholder="Nom"
            v-model="organization.lastname"
          ></b-form-input>
          <small
            class="form-text text-danger"
            v-if="
              $v.organization.lastname.$dirty &&
                !$v.organization.lastname.required
            "
          >
            Ce champs est obligatoire
          </small>
        </b-form-group>

        <b-form-group label-cols-lg="4" content-cols-lg="8" label="Téléphone *">
          <template>
            <vue-tel-input
              v-model="phoneNumber.formatted"
              @input="phoneChanges"
              required
            ></vue-tel-input>
          </template>
          <input type="hidden" v-model="organization.phone" />
            <small
              class="form-text text-danger"
              v-if="$v.organization.phone.$dirty && !$v.organization.phone.$model"
            >
              Ce champs est obligatoire
            </small>
            <small
              class="form-text text-danger"
              v-if="phoneNumber.formatted != '' && !phoneNumber.valid"
            >
              Le numéro n'est pas un numéro valide pour
              {{ phoneNumber.country.name }}</small
            >
        </b-form-group>

        <b-form-group
          label-cols-lg="4"
          content-cols-lg="8"
          label="Email *"
          description="Vos accès vous seront envoyés à cette adresse"
        >
          <b-form-input
            placeholder="Email"
            v-model="organization.email"
          ></b-form-input>
          <small
            class="form-text text-danger"
            v-if="
              $v.organization.email.$dirty && !$v.organization.email.required
            "
          >
            Ce champs est obligatoire
          </small>
          <small
            class="form-text text-danger"
            v-if="
              $v.organization.email.$dirty && !$v.organization.email.email
            "
          >
            Veuillez saisir une adresse valide
          </small>
        </b-form-group>

        <!-- <b-form-checkbox v-model="terms" value="1"
          >J'ai lu et j'accepte les
          <a
            href="https://manageo.io/conditions-generales-dutilisation/"
            target="_blank"
            >conditions générales d'utilisation</a
          ><br />
          <small
            class="form-text text-danger"
            v-if="
              $v.terms.$dirty && !$v.terms.$model
            "
          >
            Vous devez lire et accepter les conditions générales d'utilisation.
          </small>
        </b-form-checkbox> -->

        <b-form-group label-cols-lg="4" content-cols-lg="8">
          <b-button
            type="submit"
            size="lg"
            variant="danger"
            class="mt-2"
            v-if="btnSubmit"
          >
            <i class="fa fa-sign-in"></i> Inscription gratuite
          </b-button>
          <b-button size="lg" variant="secondary" class="mt-4" v-if="btnInProgress">
            <i class="fa fa-sign-in"></i> Installation en cours ...
          </b-button>
        </b-form-group>

      </b-form>
    </div>

    <div v-if="registerSuccess">
      <h2><strong>Félicitations ! </strong></h2>
      <h3>Votre compte Manageo est créé.</h3>
      <p style="font-size:15px;">
        Nous venons de vous envoyer un e-mail de validation à votre adresse
        <b> {{ registerSuccessEmail }} </b>. Il est probablement déjà dans votre
        boîte de réception.
      </p>
      <p style="font-size:15px;">A bientôt sur Manageo</p>
      <p>
        <a
          :href="registerSuccessUrl"
          class="btn host btn-lg btn-success"
          style="font-size:20px"
          target="_blank"
        >
          <i class="fa fa-rocket"></i> Lancez l'application
        </a>
      </p>
    </div>

    <div v-if="registerError">
      <h2>Une erreur s'est produite</h2>
      <p> {{registerErrorMessage}} </p>
      <p><a href="" class="btn btn-lg btn-danger">Recommencer</a></p>
    </div>

  </section>
</template>

<script lang="js">

import { required, email } from 'vuelidate/lib/validators'

  export default  {
    name: 'register',
    props: ["partnerid"],
    mounted () {

      if (this.partnerid)
        this.organization.partnerid = this.partnerid 

      var utm_source = this.$route.query.utm_source 
      var utm_medium = this.$route.query.utm_medium 
      var utm_campaign = this.$route.query.utm_campaign 
      var utm_content = this.$route.query.utm_content 

      var expiresAt = new Date(new Date().getTime() + 3600*1000*24*90).toGMTString()  // 90 days
      var utm_date = new Date().toISOString()

      if (utm_source)
      {
        this.$cookies.set('utm_source', utm_source, expiresAt)
        this.$cookies.set('utm_medium', utm_medium, expiresAt)
        this.$cookies.set('utm_content', utm_content, expiresAt)
        this.$cookies.set('utm_campaign', utm_campaign, expiresAt)
        this.$cookies.set('created', utm_date, expiresAt)
      }

      if (this.$cookies.get('utm_source'))
          this.cookie.utm_source = this.$cookies.get('utm_source') ; 
      if (this.$cookies.get('utm_medium'))
          this.cookie.utm_medium = this.$cookies.get('utm_medium') ; 
      if (this.$cookies.get('utm_content'))
          this.cookie.utm_content = this.$cookies.get('utm_content') ; 
      if (this.$cookies.get('utm_campaign'))
          this.cookie.utm_campaign = this.$cookies.get('utm_campaign') ; 
      if (this.$cookies.get('created'))
          this.cookie.created = this.$cookies.get('created') ; 

    },
    data () {
      return {
        organization : {
          name : "",
          host : "",
          firstname : "",
          lastname : "",
          email : "",
          phone : false,
          partnerid : null,
        },
        terms : false ,
        success : false,
        error: false,
        phoneNumber : {
          formatted : "",
          valid : false,
        } ,
        submitStatus : false,
        btnInProgress : false,
        btnSubmit : true,
        registerForm : true,
        registerSuccess : false, 
        registerError : false,
        registerErrorMessage: "",
        registerSuccessUrl : "" , 
        registerSuccessEmail : "",
        cookie : {
          utm_source : null, 
          utm_medium : null, 
          utm_campaign : null,
          utm_content : null,
          created : null,
        }
      }
    },
    validations: {
      organization : {
        name : { required },
        host: { required },
        phone: { required },
        email: { required, email },
        firstname: { required },
        lastname: { required },
      },
      // terms: { required, sameAs : 1},
    },
    methods: {
      slugify(field) {

          var string = this.organization[field].replace(/\s+/g, '').toLowerCase();

          var accent = [
              /[\300-\306]/g, /[\340-\346]/g, // A, a
              /[\310-\313]/g, /[\350-\353]/g, // E, e
              /[\314-\317]/g, /[\354-\357]/g, // I, i
              /[\322-\330]/g, /[\362-\370]/g, // O, o
              /[\331-\334]/g, /[\371-\374]/g, // U, u
              /[\321]/g, /[\361]/g, // N, n
              /[\307]/g, /[\347]/g, // C, c
          ];
          var noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

          for (var i = 0; i < accent.length; i++) {
              string = string.replace(accent[i], noaccent[i]);
          }
          string = string.replace("'", "");
          // console.log(string);

          this.organization.host = string ;
          this.exists(string) ;

          return string;
      },
      exists(string) {

        this.$http.post(this.baseURI+"organization/exist", {
          host : string
        })
        .then((response) => {

          if (response.data.status == "success")
          {
              this.success = response.data.message;
              this.error = false ;
              this.submit = true;
          }
          else
          {
              this.error = response.data.message;
              this.success = false ;
              this.submit = false;
          }
        })
      },
      register() {
          // console.log('submit!') ;

          this.$v.$touch() ;

          if (this.$v.$invalid) {
            this.submitStatus = 'ERROR FORM'
          } else if (this.error) {
            this.submitStatus = 'HOST ERROR'
          } else if (!this.phoneNumber.valid) {
            this.submitStatus = 'PHONE ERROR'
          // } else if (!this.terms) {
          //   this.submitStatus = 'TERMS ERROR'
          } else {
            this.submitStatus = 'OK'
            this.btnSubmit = false
            this.btnInProgress = true 

            this.organization.cookie = this.cookie ;

            this.$http.post(this.baseURI+"organization/register", this.organization)
            .then((response) => {

              if (response.data.status == 'success') {
                this.registerForm = false 
                this.registerSuccess = true 
                this.registerError = false 

                this.registerSuccessEmail = response.data.data.email 
                this.registerSuccessUrl = "https://" + response.data.data.host + "/?token="+response.data.data.token; 

                window.onMGRegister() ; 

              } else {
                this.registerForm = false 
                this.registerSuccess = false 
                this.registerError = true 
                this.registerErrorMessage = response.data.message;
              }
            })
          // console.log(this.submitStatus)
        }
      },
      phoneChanges(string, phone) {
        this.phoneNumber = phone ;

        if (phone.valid) 
          this.organization.phone = phone.number ;
        else this.organization.phone = string ;
      }
    },
    computed: {
    }
}
</script>

<style scoped lang="css">
.register {
}
.custom-control {
  z-index: -1000;
}
</style>

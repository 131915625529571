<template lang="html">

  <section class="clients">
    <div class="container">
      <h2>Plus de  1000 <strong>entreprises utilisent Manageo</strong>
</h2>


<div class="row">
  <div v-for="(client, index) in clients" :key="index" class="client col-lg-2 col-md-4 col-sm-6">
    <img :src="require('@/assets/img/clients/'+client+'.png')" class="border rounded">
  </div>
</div>

      <!-- <carousel :perPage="perPage">
        <slide>
            <div class="row">
              <div v-for="(client, index) in clients1" :key="index" class="client col-lg-2 col-md-4 col-sm-6">
                <img :src="require('@/assets/img/clients/'+client+'.png')" class="border rounded">
              </div>
            </div>
        </slide>
        <slide>
            <div class="row">
              <div v-for="(client, index) in clients2" :key="index" class="client col-lg-2 col-md-4 col-sm-6">
                <img :src="require('@/assets/img/clients/'+client+'.png')" class="border rounded">
              </div>
            </div>
        </slide>
        <slide>
            <div class="row">
              <div v-for="(client, index) in clients3" :key="index" class="client col-lg-2 col-md-4 col-sm-6">
                <img :src="require('@/assets/img/clients/'+client+'.png')" class="border rounded">
              </div>
            </div>
        </slide>
      </carousel> -->
    </div>
  </section>

</template>

<script lang="js">

// import { Carousel, Slide } from 'vue-carousel';

export default  {
    components: {
      // Carousel,
      // Slide
    },
    mounted () {
      this.clients = this.clients.sort( () => .5 - Math.random() );
      this.clients1 = this.clients.slice(0, 6)
      this.clients2 = this.clients.slice(6, 12)
      this.clients3 = this.clients.slice(12, 18)
    },
    data () {
      return {
        perPage : 1,
        clients : [
          "alazrak" ,
          "audigroup" ,
          "mutandis" ,
          "smidcom" ,
          "telquel" ,
          "mazhar" ,
          "bechix" ,
          "desco" ,
          "bonzai" ,
          "immorente" ,
          "jawjab" ,
          // "jockercuir" ,
          "maisonnicole" ,
          "medicastor" ,
          "moongarden" ,
          // "ozelacom" ,
          "prmedia" ,
          "salesuplift" ,
          "smartspace" ,
          "upek" ,
        ], 
        clients1 : [] , 
        clients2 : [] ,
        clients3 : [] 
      }
    },
}
</script>

<style scoped lang="css">
section.clients {
  margin:20px auto;
  border-width:80% ;
}
div.client {
  width:180px;
  margin-bottom:10px;
}
div img {
  width:160px;
}
</style>


<template lang="html">
  <section class="demo-request">
    <!-- Partner ID {{partnerid}} -->

  <div class="container">
    <div class="row">
      <div class="col-md-7 pr-4" v-if="partner == 'sgmaroc'">

        <h2>MA BANQUE ET MANAGEO <strong>Au quotidien</strong></h2>

        <p class="intro mb-4">Grâce à notre partenariat avec la <b>Société Générale Maroc</b>, les clients de la banque peuvent bénéficier d'une solution complète de gestion d'entreprise pour leurs besoins professionnels. En tant que client de la Société Générale Maroc, vous bénéficiez d'<b>une remise de 20%</b> sur notre produit. </p>
                
        <p class="intro mb-4">Choisir notre logiciel pour vous accompagner, c’est mettre à profit un outil puissant et intuitif afin de dynamiser votre business. Manageo vous permettra de vous concentrer sur le <b>développement de votre activité.</b>.</p>
       
        <p class="intro mb-4">Demandez dès maintenant votre <b>démonstration personnalisée</b> pour découvrir comment Manageo peut booster votre entreprise et vous faire gagner du temps et de l'argent. Rejoignez les entreprises qui ont déjà fait confiance à Manageo et prenez le contrôle de votre entreprise dès aujourd'hui !</p>

      </div>
      <div class="col-md-7 pr-4" v-else>

        <h2>UN LOGICIEL DE GESTION <strong>SIMPLE ET EFFICACE !</strong></h2>

        <p class="intro mb-4">Manageo, est un <b>logiciel de gestion commerciale complet</b> (CRM, Ventes, Achats, Clients, Fournisseurs, Recouvrement, TVA, Gestion de Stock, CA, etc.) 100% personnalisable, conçu pour booster votre entreprise en répondant sans cesse à vos besoins en constantes évolutions. </p>

        <p class="intro mb-4">Choisir notre logiciel pour vous accompagner, c’est mettre à profit un outil puissant et intuitif afin de dynamiser votre business. Manageo vous permettra de vous concentrer sur le <b>développement de votre activité.</b>.</p>
       
        <p class="intro mb-4">Demandez dès maintenant votre <b>démonstration personnalisée</b> pour découvrir comment Manageo peut booster votre entreprise et vous faire gagner du temps et de l'argent. Rejoignez les entreprises qui ont déjà fait confiance à Manageo et prenez le contrôle de votre entreprise dès aujourd'hui !</p>

      </div>
      <div class="col-md-5" id="form">

      <a name="form"></a> 

      <div v-if="requestForm">

      <!-- <h4>Voir la démonstration de Manageo</h4><br/> -->

        <b-form @submit.prevent="send">
          <input type="text" name="country" :value="request.country" style="display:none"/>
          <b-form-group
            label-cols-lg="4"
            content-cols-lg="8"
            label="Raison Sociale *"
          >
            <b-form-input
              name="company"
              placeholder="Raison Sociale"
              v-model="$v.request.company.$model"
            ></b-form-input>
            <small
              class="form-text text-danger"
              v-if="$v.request.company.$dirty && !$v.request.company.required"
            >
              Ce champs est obligatoire
            </small>
          </b-form-group>

          <b-form-group label-cols-lg="4" content-cols-lg="8" label="Nom *">
            <b-form-input
              name="lastname"
              placeholder="Nom"
              v-model="request.lastname"
            ></b-form-input>
            <small
              class="form-text text-danger"
              v-if="
                $v.request.lastname.$dirty &&
                  !$v.request.lastname.required
              "
            >
              Ce champs est obligatoire
            </small>
          </b-form-group>

          <b-form-group label-cols-lg="4" content-cols-lg="8" label="Prénom *">
            <b-form-input
              name="firstname"
              placeholder="Prénom"
              v-model="request.firstname"
            ></b-form-input>
            <small
              class="form-text text-danger"
              v-if="
                $v.request.firstname.$dirty &&
                  !$v.request.firstname.required
              "
            >
              Ce champs est obligatoire
            </small>
          </b-form-group>

          <b-form-group label-cols-lg="4" content-cols-lg="8" label="Fonction *">
            <b-form-input
              name="jobtitle"
              placeholder="Fonction"
              v-model="request.position"
            ></b-form-input>
            <small
              class="form-text text-danger"
              v-if="
                $v.request.position.$dirty &&
                  !$v.request.position.required
              "
            >
              Ce champs est obligatoire
            </small>
          </b-form-group>

          <b-form-group label-cols-lg="4" content-cols-lg="8" label="Téléphone *">
            <template>
              <vue-tel-input
                v-model="phoneNumber.formatted"
                @input="phoneChanges"
                required
              ></vue-tel-input>
            </template>
            <input type="hidden" name="phone" v-model="request.phone" />
              <small
                class="form-text text-danger"
                v-if="$v.request.phone.$dirty && !$v.request.phone.$model"
              >
                Ce champs est obligatoire
              </small>
              <small
                class="form-text text-danger"
                v-if="phoneNumber.formatted != '' && !phoneNumber.valid"
              >
                Le numéro n'est pas un numéro valide pour
                {{ phoneNumber.country.name }}</small
              >
          </b-form-group>

          <b-form-group
            label-cols-lg="4"
            content-cols-lg="8"
            label="Email *"
          >
            <b-form-input
              name="email"
              placeholder="Email"
              v-model="request.email"
            ></b-form-input>
            <small
              class="form-text text-danger"
              v-if="
                $v.request.email.$dirty && !$v.request.email.required
              "
            >
              Ce champs est obligatoire
            </small>
            <small
              class="form-text text-danger"
              v-if="
                $v.request.email.$dirty && !$v.request.email.email
              "
            >
              Veuillez saisir une adresse valide
            </small>
          </b-form-group>

          <b-form-group label-cols-lg="4" content-cols-lg="8" label="Ville *">
            <b-form-input
              name="city"
              placeholder="Ville"
              v-model="request.city"
            ></b-form-input>
            <small
              class="form-text text-danger"
              v-if="
                $v.request.city.$dirty &&
                  !$v.request.city.required
              "
            >
              Ce champs est obligatoire
            </small>
          </b-form-group>

          <b-form-group label-cols-lg="4" content-cols-lg="8" label="Pays *">
            <v-select 
              placeholder="Pays" 
              :options="countries" 
              v-model="request.country" 
              :reduce="country => country.label">
            </v-select>
            <small
              class="form-text text-danger"
              v-if="
                $v.request.country.$dirty &&
                  !$v.request.country.required
              "
            >
              Ce champs est obligatoire
            </small>
          </b-form-group>

          <b-form-group label-cols-lg="4" content-cols-lg="8">
            <b-button
              block 
              type="submit"
              size="lg"
              variant="danger"
              class="mt-2"
            >
              <i class="pi pi-phone"></i> Parler à un conseiller
            </b-button>
          </b-form-group>

        </b-form>
      </div>
      <div v-else>
        <div v-if="formSuccess" class="text-center">
          <h2><strong>Merci ! </strong></h2>
          <h3>Votre demande est bien reçue.</h3>
          <p>
            <i class="pi pi-thumbs-up h1 text-success"></i>
          </p>
          <p style="font-size:15px;">
            Un de nos conseillers vous contactera dans les plus brefs délais. 
          </p>
          <p style="font-size:15px;">A bientôt sur Manageo</p>
        </div>

        <div v-if="!formSuccess" class="text-center">
          <h2>Une <strong>erreur</strong> s'est produite</h2>
          <p> Veuillez recommencer. </p>
          <p><a href="" class="btn btn-lg btn-danger">Recommencer</a></p>
        </div>
      
      </div>


      </div>
    </div>
  </div>
  </section>
</template>

<script lang="js">

import { required, email } from 'vuelidate/lib/validators'
import countries from "../data/all-countries" ;
// import * as _ from 'lodash' ;
      
export default  {
  props: ["partner", "country", "utm_source"],
  components: {
  },
  data () {
    return {
      countries : countries,
      request : {
        company : null,
        firstname : null,
        lastname : null,
        position : null,
        phone : false,
        email : null,
        city : null,
        country : null,
        utm_source : null, 
        utm_medium : "landing-page", 
        utm_campaign : null,
        utm_content : null,
        utm_date : null,
      },
      terms : false ,
      formSuccess : false,
      formStatus: false,
      requestForm : true,
      phoneNumber : {
        formatted : "",
        valid : false,
      } ,
    }
  },
  validations: {
    request : {
      company : { required },
      phone: { required },
      email: { required, email },
      firstname: { required },
      lastname: { required },
      city: { required },
      country: { required },
      position: { required },
    },
    // terms: { required, sameAs : 1},
  },
  mounted () {

    switch(this.country)
    {
      case "MA" : this.request.country = "Maroc" ; 
      break ; 
      case "CI" : this.request.country = "Côte d'Ivoire" ; 
      break ;
      case "SN" : this.request.country = "Sénégal" ; 
      break ;
    }

    // if (this.partnerid)
    //   this.request.partnerid = this.partnerid 

    // console.log(this.utm_source)

    var utm_source = this.utm_source != undefined ? this.utm_source : this.$route.query.utm_source 
    var utm_medium = this.$route.query.utm_medium 
    var utm_content = this.$route.query.utm_content 
    var utm_campaign = this.$route.query.utm_campaign 

    console.log({utm_source})

    var expiresAt = new Date(new Date().getTime() + 3600*1000*24*90).toGMTString()  // 90 days
    var utm_date = new Date().toISOString()

    if (utm_source)
    {
      this.$cookies.set('utm_source', utm_source, expiresAt)
      this.$cookies.set('utm_medium', utm_medium, expiresAt)
      this.$cookies.set('utm_campaign', utm_campaign, expiresAt)
      this.$cookies.set('utm_content', utm_content, expiresAt)
      this.$cookies.set('utm_date', utm_date, expiresAt)
    }

    if (this.$cookies.get('utm_source'))
        this.request.utm_source = this.$cookies.get('utm_source') ; 
    if (this.$cookies.get('utm_medium'))
        this.request.utm_medium = this.$cookies.get('utm_medium') ; 
    if (this.$cookies.get('utm_campaign'))
        this.request.utm_campaign = this.$cookies.get('utm_campaign') ; 
    if (this.$cookies.get('utm_content'))
        this.request.utm_content = this.$cookies.get('utm_content') ; 
    if (this.$cookies.get('utm_date'))
        this.request.utm_date = this.$cookies.get('utm_date') ; 
  },
  methods: {
    send() {
        // console.log('submit!') ;

        this.$v.$touch() ;

        if (this.$v.$invalid) {
          this.formStatus= 'ERROR_FORM'
        } else if (!this.phoneNumber.valid) {
          this.formStatus= 'PHONE_ERROR'
        // } else if (!this.terms) {
        //   this.formStatus= 'TERMS ERROR'
        } else {
          this.formStatus= 'OK'

          // console.log("request", this.request) ;

          this.$http.post(this.baseURI+"demo-request", this.request)
          .then((response) => {

            this.requestForm = false 

            if (response.data.status == 'success') { 
              this.formSuccess = true 

              // window.onMGRegister() ; 
              // this.$router.push('/thankyou')

            } else {
              this.formSuccess = false 
            }
          })
      }
    },
    phoneChanges(string, phone) {
      this.phoneNumber = phone ;

      if (phone.valid) 
        this.request.phone = phone.number ;
      else this.request.phone = string ;
    }
  },
  computed: {
  }
}
</script>

<style scoped lang="css">
.btn.btn-lg.btn-block {
  font-size:18px;
  margin-bottom:30px;
}
legend.col-form-label { 
  text-align: right;
}
.custom-control {
  z-index: -1000;
}
p.intro {
  font-size:17px; 
  line-height:30px;
  margin:20px 0px;
}
p.intro ul li{
  font-size:17px; 
}
</style>

<template lang="html">
  <section class="header">
      <div class="container">
          <div class="row">
              <div class="col-md-6 text-left">
                  <router-link to="/">
                      <img src="@/assets/img/logo.png"/>
                  </router-link>
              </div>
              <div class="col-md-6 text-right">
                  <span><a :href="'https://wa.me/' + phone + '/?text=Bonjour, \nPourrais-je avoir plus d\'informations s\'il vous plait concernant ce logiciel ?'" target="_blank">{{ this.phone }}</a></span><br />
                  7 jours d'essai gratuit
              </div>
          </div>
      </div>
  </section>
</template>

<script lang="js">

  export default  {
    props: [],
    data () {
      return {
        phone : null
      }
    },
    mounted () {

        console.log(this.$route) 

        switch(this.$route.name)
        {
          case "ci" : this.phone = "+2250749005013" ;
          break ; 
          case "sn" : this.phone = "+221777072768" ;
          break ; 
          default : this.phone = "+212666653437" ;
        }

        return this.$route.name;
    },
    methods: {

    },
  computed:{
  }
}
</script>

<style scoped lang="css">
.header {
  padding: 20px;
  background: #ffffff;
  margin: 10px auto;
  font-size: 20px;
  text-align: right;
  color: #444;
}
img {
  width: 300px;
}
span {
  color: #0da3e2;
  font-size: 24px;
  font-weight: bold;
}
</style>


<template lang="html">
  <section class="testimonials">
  <div class="container">
      <div class="text-center">
      <h2>Ce qu'ils disent sur <strong> Manageo</strong></h2>
      </div>
      <div class="accordion" role="tablist">
          <b-card no-body class="mb-1"
          v-for="(testimonial, index) in testimonials"
          :key="index">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block variant="default" v-b-toggle="'accordion-' + index" :aria-controls="'accordion-'+index">
              {{ testimonial.author }} <small>{{ testimonial.company }}</small>
            </b-button>
          </b-card-header><b-collapse :id="'accordion-'+index" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              {{ testimonial.content }}
            </b-card-body>
          </b-collapse>
        </b-card>
        </div>

        <p class="text-center">
            <b-button variant="danger" size="lg"  href="#form" class="mt-3">
              <i class="m-icon-swapright m-icon-white"></i> Discuter avec un conseiller
            </b-button>
        </p>
    </div>
  </section>
</template>


<script lang="js">

import testimonials from "../data/testimonials" ;

  export default  {
    name: 'testimonials',
    props: [],
    mounted () {

    },
    data () {
      return {
        testimonials : testimonials
      }
    },
    methods: {

    },
    computed: {

    }
}
</script>

<style scoped lang="css">
.card-body {
  line-height: 20px;
}
.card-header button {
  font-size: 18px;
  text-transform: uppercase;
}
</style>

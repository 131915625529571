<template lang="html">
  <section class="numbers grey-block">
    <b-container>
        <div class="text-center">
        <h2>Manageo <strong>en chiffres</strong></h2>
        </div>
        <b-row>
          <b-col lg="4" class="item">
            <span>{{ users }}</span> 
             Utilisateurs actifs
          </b-col>
          <b-col lg="4" class="item"> 
            <span>{{ documents }}</span> 
            Documents créés
          </b-col>
          <b-col lg="4" class="item">
            <span>{{ employees }}</span> 
            Collaborateurs
          </b-col>
        </b-row>
    </b-container>
  </section>
</template>

<script lang="js">
export default  {
  props: [],  
  data () {
    return {
      users : "3 000",
      documents : "2 750 000",
      employees : "10",
    }
  },
}
</script>

<style scoped lang="css">
.numbers {
  margin-top: 40px;
  margin-bottom: 40px;
}
.item {
  font-size: 20px;
  padding: 10px;
  text-align: center;
}
.item span {
  color: #159eee;
  font-size: 40px;
  font-weight: bold;
  padding: 20px;
  margin: 10px;
  display: block;
}
</style>

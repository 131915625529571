import Vue from "vue";
import VueRouter from "vue-router";
import VueTelInput from "vue-tel-input";
import VueCookies from "vue-cookies";
import vSelect from 'vue-select'
import axios from "axios";
import App from "./App.vue";

import { BootstrapVue } from "bootstrap-vue";
import Vuelidate from "vuelidate";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-tel-input/dist/vue-tel-input.css";
import "primeicons/primeicons.css";
import 'vue-select/dist/vue-select.css';

import "@/assets/css/style.css";
import "@/assets/css/style-responsive.css";

Vue.use(BootstrapVue);
Vue.use(VueCookies);
Vue.use(VueRouter);
Vue.use(VueTelInput);
Vue.use(Vuelidate);
Vue.component('v-select', vSelect)

Vue.prototype.baseURI = process.env.VUE_APP_BASE_URI;
Vue.prototype.$http = axios;
Vue.config.productionTip = false;

import Page from "./pages/ma/Page";
import Legafrik from "./pages/legafrik/Page";
import Amde from "./pages/amde/Page";
import Ci from "./pages/ci/Page";
import Sn from "./pages/sn/Page";
import Amca from "./pages/amca/Page";
import SocieteGenerale from "./pages/sg/Page";
// import ThankYou from "./pages/ma/ThankYou"

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      name: "legafrik",
      path: "/legafrik",
      component: Legafrik,
      meta: { title: "Legafrik partenaire de Manageo.io en Côte d'Ivoire" },
    },
    {
      name: "amde",
      path: "/amde",
      component: Amde,
      meta: {
        title:
          "AMDE partenaire de Manageo.io, logiciel de gestion commerciale en ligne",
      },
    },
    {
      name: "ci",
      path: "/ci",
      component: Ci,
      meta: {
        title:
          "Logiciel de facturation | Logiciel de gestion commerciale | Côte D'Ivoire",
      },
    },
    {
      name: "sn",
      path: "/sn",
      component: Sn,
      meta: {
        title:
          "Logiciel de facturation | Logiciel de gestion commerciale | Sénégal",
      },
    },
    {
      name: "sgmaroc",
      path: "/sgmaroc",
      component: SocieteGenerale,
      meta: {
        title:
          "Logiciel de facturation | Logiciel de gestion commerciale | Société Générale",
      },
    },
    {
      name: "amca",
      path: "/amca",
      component: Amca,
      meta: {
        title:
          "Logiciel de facturation | Logiciel de gestion commerciale | Centres d'affaires",
      },
    },
    // {
    //   name: "thankyou",
    //   path: "/thankyou",
    //   component: ThankYou,
    //   meta: {
    //     title:
    //       "Logiciel de facturation | Logiciel de gestion commerciale | Manageo.io",
    //   },
    // },
    {
      name: "ma",
      path: "**",
      component: Page,
      meta: {
        title:
          "Logiciel de facturation | Logiciel de gestion commerciale | Manageo.io",
      },
    },
  ],
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

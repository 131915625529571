const countries = [
    {"code":"AD","label":"Andorre"},
    {"code":"AE","label":"Émirats Arabes Unis"},
    {"code":"AF","label":"Afghanistan"},
    {"code":"AG","label":"Antigua-et-Barbuda"},
    {"code":"AI","label":"Anguilla"},
    {"code":"AL","label":"Albanie"},
    {"code":"AM","label":"Arménie"},
    {"code":"AN","label":"Antilles Néerlandaises"},
    {"code":"AO","label":"Angola"},
    {"code":"AQ","label":"Antarctique"},
    {"code":"AR","label":"Argentine"},
    {"code":"AS","label":"Samoa Américaines"},
    {"code":"AT","label":"Autriche"},
    {"code":"AU","label":"Australie"},
    {"code":"AW","label":"Aruba"},
    {"code":"AZ","label":"Azerbaédjan"},
    {"code":"BA","label":"Bosnie-Herzégovine"},
    {"code":"BB","label":"Barbade"},
    {"code":"BD","label":"Bangladesh"},
    {"code":"BE","label":"Belgique"},
    {"code":"BF","label":"Burkina Faso"},
    {"code":"BG","label":"Bulgarie"},
    {"code":"BH","label":"Bahreïn"},
    {"code":"BI","label":"Burundi"},
    {"code":"BJ","label":"Bénin"},
    {"code":"BL","label":"Saint-Barthélemy"},
    {"code":"BM","label":"Bermudes"},
    {"code":"BN","label":"Brunei"},
    {"code":"BO","label":"Bolivie"},
    {"code":"BQ","label":"Saint-Eustache"},
    {"code":"BR","label":"Brésil"},
    {"code":"BS","label":"Bahamas"},
    {"code":"BT","label":"Bhoutan"},
    {"code":"BV","label":"Île Bouvet"},
    {"code":"BW","label":"Botswana"},
    {"code":"BY","label":"Bielorussie"},
    {"code":"BZ","label":"Belize"},
    {"code":"CA","label":"Canada"},
    {"code":"CC","label":"Îles Cocos "},
    {"code":"CD","label":"République Démocratique du Congo"},
    {"code":"CF","label":"République Centrafricaine"},
    {"code":"CG","label":"République du Congo"},
    {"code":"CH","label":"Suisse"},
    {"code":"CI","label":"Côte d'Ivoire"},
    {"code":"CK","label":"Îles Cook"},
    {"code":"CL","label":"Chili"},
    {"code":"CM","label":"Cameroun"},
    {"code":"CN","label":"Chine"},
    {"code":"CO","label":"Colombie"},
    {"code":"CR","label":"Costa Rica"},
    {"code":"CU","label":"Cuba"},
    {"code":"CV","label":"Cap-Vert"},
    {"code":"CX","label":"Île Christmas"},
    {"code":"CY","label":"Chypre"},
    {"code":"CZ","label":"République Tchèque"},
    {"code":"DE","label":"Allemagne"},
    {"code":"DJ","label":"Djibouti"},
    {"code":"DK","label":"Danemark"},
    {"code":"DM","label":"Dominique"},
    {"code":"DO","label":"République Dominicaine"},
    {"code":"DZ","label":"Algérie"},
    {"code":"EC","label":"Équateur"},
    {"code":"EE","label":"Estonie"},
    {"code":"EG","label":"Egypte"},
    {"code":"EH","label":"Sahara Occidental"},
    {"code":"ER","label":"Érythrée"},
    {"code":"ES","label":"Espagne"},
    {"code":"ET","label":"Éthiopie"},
    {"code":"FI","label":"Finlande"},
    {"code":"FJ","label":"Fidji"},
    {"code":"FK","label":"Îles Malouines"},
    {"code":"FM","label":"États Fédérés de Micronésie"},
    {"code":"FO","label":"Îles Féroé"},
    {"code":"FR","label":"France"},
    {"code":"GA","label":"Gabon"},
    {"code":"GB","label":"Royaume-Uni"},
    {"code":"GD","label":"Grenade"},
    {"code":"GE","label":"Géorgie"},
    {"code":"GF","label":"Guyane Française"},
    {"code":"GG","label":"Guernsey"},
    {"code":"GH","label":"Ghana"},
    {"code":"GI","label":"Gibraltar"},
    {"code":"GL","label":"Groenland"},
    {"code":"GM","label":"Gambie"},
    {"code":"GN","label":"Guinée"},
    {"code":"GP","label":"Guadeloupe"},
    {"code":"GQ","label":"République de Guinée équatoriale"},
    {"code":"GR","label":"Grèce"},
    {"code":"GS","label":"Géorgie du Sud et les Îles Sandwich du Sud"},
    {"code":"GT","label":"Guatemala"},
    {"code":"GU","label":"Guam"},
    {"code":"GW","label":"Guinée-Bissau"},
    {"code":"GY","label":"Guyana"},
    {"code":"GZ","label":"Bande de Gaza"},
    {"code":"HK","label":"Hong Kong"},
    {"code":"HM","label":"Îles Heard et Mcdonald"},
    {"code":"HN","label":"Honduras"},
    {"code":"HR","label":"Croatie"},
    {"code":"HT","label":"Haïti"},
    {"code":"HU","label":"Hongrie"},
    {"code":"ID","label":"Indonésie"},
    {"code":"IE","label":"Irlande "},
    {"code":"IL","label":"Israël"},
    {"code":"IM","label":"Ile de Man"},
    {"code":"IN","label":"Inde"},
    {"code":"IO","label":"Territoire Britannique de l'Océan Indien"},
    {"code":"IQ","label":"Irak"},
    {"code":"IR","label":"Iran"},
    {"code":"IS","label":"Islande"},
    {"code":"IT","label":"Italie"},
    {"code":"JE","label":"Jersey"},
    {"code":"JM","label":"Jamaïque"},
    {"code":"JO","label":"Jordanie"},
    {"code":"JP","label":"Japon"},
    {"code":"KE","label":"Kenya"},
    {"code":"KG","label":"Kirghizistan"},
    {"code":"KH","label":"Cambodge"},
    {"code":"KI","label":"Kiribati"},
    {"code":"KM","label":"Comores"},
    {"code":"KN","label":"Saint-Kitts-et-Nevis"},
    {"code":"KP","label":"Corée du Nord"},
    {"code":"KR","label":"Corée du Sud"},
    {"code":"KW","label":"Koweït"},
    {"code":"KY","label":"Îles Caïmans"},
    {"code":"KZ","label":"Kazakhstan"},
    {"code":"LA","label":"Laos"},
    {"code":"LB","label":"Liban"},
    {"code":"LC","label":"Sainte-Lucie"},
    {"code":"LI","label":"Liechtenstein"},
    {"code":"LK","label":"Sri Lanka"},
    {"code":"LR","label":"Libéria"},
    {"code":"LS","label":"Lesotho"},
    {"code":"LT","label":"Lituanie"},
    {"code":"LU","label":"Luxembourg"},
    {"code":"LV","label":"Lettonie"},
    {"code":"LY","label":"Libye"},
    {"code":"MA","label":"Maroc"},
    {"code":"MC","label":"Monaco"},
    {"code":"MD","label":"Moldavie"},
    {"code":"ME","label":"Monténégro"},
    {"code":"MG","label":"Madagascar"},
    {"code":"MH","label":"Îles Marshall"},
    {"code":"MK","label":"Macédoine"},
    {"code":"ML","label":"Mali"},
    {"code":"MM","label":"Birmanie"},
    {"code":"MN","label":"Mongolie"},
    {"code":"MO","label":"Macao"},
    {"code":"MP","label":"Îles Mariannes du Nord"},
    {"code":"MQ","label":"Martinique"},
    {"code":"MR","label":"Mauritanie"},
    {"code":"MS","label":"Montserrat"},
    {"code":"MT","label":"Malte"},
    {"code":"MU","label":"Maurice"},
    {"code":"MV","label":"Maldives"},
    {"code":"MW","label":"Malawi"},
    {"code":"MX","label":"Mexique"},
    {"code":"MY","label":"Malaisie"},
    {"code":"MZ","label":"Mozambique"},
    {"code":"NA","label":"Namibie"},
    {"code":"NC","label":"Nouvelle-Calédonie"},
    {"code":"NE","label":"Niger"},
    {"code":"NF","label":"Île Norfolk"},
    {"code":"NG","label":"Nigeria"},
    {"code":"NI","label":"Nicaragua"},
    {"code":"NL","label":"Pays-Bas"},
    {"code":"NO","label":"Norvège"},
    {"code":"NP","label":"Népal"},
    {"code":"NR","label":"Nauru"},
    {"code":"NU","label":"Niué"},
    {"code":"NZ","label":"Nouvelle Zélande"},
    {"code":"OM","label":"Oman"},
    {"code":"PA","label":"Panama"},
    {"code":"PE","label":"Pérou"},
    {"code":"PF","label":"Polynésie Française"},
    {"code":"PG","label":"Papouasie-Nouvelle-Guinée"},
    {"code":"PH","label":"Philippines"},
    {"code":"PK","label":"Pakistan"},
    {"code":"PL","label":"Pologne"},
    {"code":"PM","label":"Saint-Pierre-et-Miquelon"},
    {"code":"PN","label":"Pitcairn"},
    {"code":"PR","label":"Porto Rico"},
    {"code":"PS","label":"Territoire Palestinien Occupé"},
    {"code":"PT","label":"Portugal"},
    {"code":"PW","label":"Palaos"},
    {"code":"PY","label":"Paraguay"},
    {"code":"QA","label":"Qatar"},
    {"code":"RE","label":"Réunion"},
    {"code":"RO","label":"Roumanie"},
    {"code":"RS","label":"Serbie"},
    {"code":"RU","label":"Russie"},
    {"code":"RW","label":"Rwanda"},
    {"code":"SA","label":"Arabie saoudite"},
    {"code":"SB","label":"Iles Salomon"},
    {"code":"SC","label":"Seychelles"},
    {"code":"SD","label":"Soudan"},
    {"code":"SE","label":"Suède"},
    {"code":"SG","label":"Singapour"},
    {"code":"SH","label":"Sainte-Hélène"},
    {"code":"SI","label":"Slovénie"},
    {"code":"SJ","label":"Svalbard etÎle Jan Mayen"},
    {"code":"SK","label":"Slovaquie"},
    {"code":"SL","label":"Sierra Leone"},
    {"code":"SM","label":"Saint-Marin"},
    {"code":"SN","label":"Sénégal"},
    {"code":"SO","label":"Somalie"},
    {"code":"SR","label":"Surinam"},
    {"code":"ST","label":"Säo Tomé-et-Principe"},
    {"code":"SV","label":"Salvador"},
    {"code":"SX","label":"Saint-Martin"},
    {"code":"SY","label":"Syrie"},
    {"code":"SZ","label":"Swaziland"},
    {"code":"TC","label":"Îles Turques-et-Caïques"},
    {"code":"TD","label":"Tchad"},
    {"code":"TF","label":"Terres Australes Françaises"},
    {"code":"TG","label":"Togo"},
    {"code":"TH","label":"Thaïlande"},
    {"code":"TJ","label":"Tadjikistan"},
    {"code":"TK","label":"Tokelau"},
    {"code":"TL","label":"Timor-Leste"},
    {"code":"TM","label":"Turkménistan"},
    {"code":"TN","label":"Tunisie"},
    {"code":"TO","label":"Tonga"},
    {"code":"TR","label":"Turquie"},
    {"code":"TT","label":"Trinité-et-Tobago"},
    {"code":"TV","label":"Tuvalu"},
    {"code":"TW","label":"Taïwan"},
    {"code":"TZ","label":"Tanzanie"},
    {"code":"UA","label":"Ukraine"},
    {"code":"UG","label":"Ouganda"},
    {"code":"UM","label":"Îles Mineures Éloignées des États-Unis"},
    {"code":"US","label":"Etats-Unis d'Amérique"},
    {"code":"UY","label":"Uruguay"},
    {"code":"UZ","label":"Ouzbékistan"},
    {"code":"VA","label":"Cité du Vatican"},
    {"code":"VC","label":"Saint-Vincent-et-les Grenadines"},
    {"code":"VE","label":"Vénézuéla"},
    {"code":"VG","label":"Îles Vierges britanniques"},
    {"code":"VI","label":"Îles Vierges des États-Unis"},
    {"code":"VN","label":"Vietnam"},
    {"code":"VU","label":"Vanuatu"},
    {"code":"WF","label":"Wallis et Futuna"},
    {"code":"WS","label":"Samoa"},
    {"code":"XK","label":"Kosovo"},
    {"code":"YE","label":"Yémen"},
    {"code":"YT","label":"Mayotte"},
    {"code":"YU","label":"Yougoslavie"},
    {"code":"ZA","label":"Afrique du Sud"},
    {"code":"ZM","label":"Zambie"},
    {"code":"ZW","label":"Zimbabwe"}
]

export default countries ;
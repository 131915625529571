<template lang="html">
  <div class="page">
    <my-header></my-header>
    
    <Separator />

    <DemoRequest country="SN"></DemoRequest>
    
    <Features :color="color" />

    <Pricing currency="XOF" :color="color" />

    <!-- <Numbers :color="color" /> -->

    <!-- <Testimonials :color="color" /> -->

    <Arguments :color="color" />

    <Faq />

    <my-footer></my-footer>
  </div>
</template>

<script lang="js">

import MyHeader from "../../components/Header";
import Features from "../../components/Features";
import DemoRequest from "../../components/DemoRequest";
import Pricing from "../../components/Pricing";
// import Testimonials from "../../components/Testimonials";
// import Numbers from "../../components/Numbers";
import Separator from "../../components/Separator.vue";
import MyFooter from "./Footer";
import Arguments from "../../components/Arguments";
import Faq from "../../components/Faq";

  export default  {
    name: 'page',
    components: {
      MyHeader,
      MyFooter,
      Features,
      DemoRequest,
      Pricing,
      // Testimonials,
      Separator,
      // Numbers,
      Arguments,
      Faq
    },
    data() {
      return {
        color : "#e14d43"
      }
    },
    props: [],
}
</script>

<style scoped lang="css">
</style>

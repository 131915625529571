<template lang="html">
  <div class="page">
    <Header></Header>

    <Separator />

    <DemoRequest country="MA" utm_source="sgmaroc" partner="sgmaroc"></DemoRequest>

    <Separator />
    
    <Clients />

    <Features />

    <Pricing currency="MAD" discount="20" />

    <!-- <Numbers /> -->

    <!-- <Testimonials /> -->

    <Arguments />

    <Faq />
    
    <Separator />

    <my-footer></my-footer>
  </div>
</template>

<script lang="js">

import Header from "./Header";
import Features from "../../components/Features";
import DemoRequest from "../../components/DemoRequest";
import Pricing from "../../components/Pricing";
// import Testimonials from "../../components/Testimonials";
// import Numbers from "../../components/Numbers";
import Separator from "../../components/Separator.vue";
import MyFooter from "../../components/Footer";
import Arguments from "../../components/Arguments";
import Faq from "../../components/Faq";
import Clients from "../../components/Clients";

  export default  {
    name: 'page',
    components: {
      Header,
      MyFooter,
      Features,
      DemoRequest,
      Pricing,
      // Testimonials,
      Separator,
      // Numbers,
      Faq,
      Arguments,
      Clients
    },
    props: [],
    mounted () {

    },
    data () {
      return {

      }
    },
}
</script>

<style scoped lang="css">
i.blue {
  color:#FFFFFF!important;
  border-color : var(--red) !important;
}
h1 strong, h2 strong, h3 strong, h4 strong {
  color:var(--red) !important;
  font-weight:bold;
}
.card-title[data-v-1a870274] {
    background-color:var(--red) !important;
}
i.pi {
    background: var(--red) !important;
}
</style>

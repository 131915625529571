<template lang="html">
  <section class="features grey-block">
    <div class="container">
      <div class="text-center">
        <h2>Des fonctionnalités <strong>sur-mesure</strong></h2>
      </div>

      <div class="row service-box">
        <div class="col-md-4">
          <div class="service-box-heading">
            <em><i class="pi pi-users blue"></i></em>
            <span>Gagnez du temps</span>
          </div>
          <p>
            Un outil de travail pour centraliser les flux d'informations dans l'entreprise, simplifier votre gestion  commerciale, le suivi de votre trésorerie ainsi que la gestion de votre stock. Nous avons pensé à tout pour vous faire gagner du temps.
          </p>
        </div>
        <div class="col-md-4">
          <div class="service-box-heading">
            <em><i class="pi pi-chart-line blue"></i></em>
            <span>Boostez vos ventes</span>
          </div>
          <p>
            Gestion de vos contacts et des opportunités, création de devis, saisie des commandes, bons de livraison, facturation récurrente… Nous vous aidons concrètement à faire de la croissance.
          </p>
        </div>
        <div class="col-md-4">
          <div class="service-box-heading">
            <em><i class="pi pi-dollar blue"></i></em>
            <span>Simplifiez votre compta</span>
          </div>
          <p>
           Facturation et paiement, saisie des achats, recouvrement, rapprochement bancaire, suivi des chèques… Nous avons créé des outils de gestion pour vous simplifier la vie.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">

  export default  {
    name: 'features',
    props: [],
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
}


</script>

<style scoped lang="css">
.service-box .service-box-heading {
  padding: 12px 0;
  box-sizing: border-box;
}
.service-box .service-box-heading i.blue {
  border: 1px solid #0da3e2;
  color: #0da3e2;
  border-radius: 10%;
  font-size: 30px;
  padding: 5px;
}
.service-box .service-box-heading span {
  color: #444;
  font-size: 21px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 10px;
  margin-left: 10px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"register"},[_c('a',{attrs:{"name":"form"}}),(_vm.registerForm)?_c('div',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-cols-lg":"4","content-cols-lg":"8","label":"Entreprise *"}},[_c('b-form-input',{attrs:{"placeholder":"Raison Sociale"},on:{"input":function($event){return _vm.slugify('name')}},model:{value:(_vm.$v.organization.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.organization.name, "$model", $$v)},expression:"$v.organization.name.$model"}}),(_vm.$v.organization.name.$dirty && !_vm.$v.organization.name.required)?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" Ce champs est obligatoire ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols-lg":"4","content-cols-lg":"8","label":"Page de connexion *"}},[_c('b-input-group',{staticClass:"mb-2 mr-sm-2 mb-sm-0",attrs:{"prepend":"https://","append":".manageo.io"}},[_c('b-form-input',{attrs:{"placeholder":"raisonsociale"},on:{"input":function($event){return _vm.slugify('host')}},model:{value:(_vm.organization.host),callback:function ($$v) {_vm.$set(_vm.organization, "host", $$v)},expression:"organization.host"}})],1),(_vm.success)?_c('small',{staticClass:"form-text text-success"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.success)}})]):_vm._e(),(_vm.error)?_c('small',{staticClass:"form-text text-danger"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.error)}})]):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols-lg":"4","content-cols-lg":"8","label":"Prénom *"}},[_c('b-form-input',{attrs:{"placeholder":"Prénom"},model:{value:(_vm.organization.firstname),callback:function ($$v) {_vm.$set(_vm.organization, "firstname", $$v)},expression:"organization.firstname"}}),(
            _vm.$v.organization.firstname.$dirty &&
              !_vm.$v.organization.firstname.required
          )?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" Ce champs est obligatoire ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols-lg":"4","content-cols-lg":"8","label":"Nom *"}},[_c('b-form-input',{attrs:{"placeholder":"Nom"},model:{value:(_vm.organization.lastname),callback:function ($$v) {_vm.$set(_vm.organization, "lastname", $$v)},expression:"organization.lastname"}}),(
            _vm.$v.organization.lastname.$dirty &&
              !_vm.$v.organization.lastname.required
          )?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" Ce champs est obligatoire ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols-lg":"4","content-cols-lg":"8","label":"Téléphone *"}},[[_c('vue-tel-input',{attrs:{"required":""},on:{"input":_vm.phoneChanges},model:{value:(_vm.phoneNumber.formatted),callback:function ($$v) {_vm.$set(_vm.phoneNumber, "formatted", $$v)},expression:"phoneNumber.formatted"}})],_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.organization.phone),expression:"organization.phone"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.organization.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.organization, "phone", $event.target.value)}}}),(_vm.$v.organization.phone.$dirty && !_vm.$v.organization.phone.$model)?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" Ce champs est obligatoire ")]):_vm._e(),(_vm.phoneNumber.formatted != '' && !_vm.phoneNumber.valid)?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" Le numéro n'est pas un numéro valide pour "+_vm._s(_vm.phoneNumber.country.name))]):_vm._e()],2),_c('b-form-group',{attrs:{"label-cols-lg":"4","content-cols-lg":"8","label":"Email *","description":"Vos accès vous seront envoyés à cette adresse"}},[_c('b-form-input',{attrs:{"placeholder":"Email"},model:{value:(_vm.organization.email),callback:function ($$v) {_vm.$set(_vm.organization, "email", $$v)},expression:"organization.email"}}),(
            _vm.$v.organization.email.$dirty && !_vm.$v.organization.email.required
          )?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" Ce champs est obligatoire ")]):_vm._e(),(
            _vm.$v.organization.email.$dirty && !_vm.$v.organization.email.email
          )?_c('small',{staticClass:"form-text text-danger"},[_vm._v(" Veuillez saisir une adresse valide ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols-lg":"4","content-cols-lg":"8"}},[(_vm.btnSubmit)?_c('b-button',{staticClass:"mt-2",attrs:{"type":"submit","size":"lg","variant":"danger"}},[_c('i',{staticClass:"fa fa-sign-in"}),_vm._v(" Inscription gratuite ")]):_vm._e(),(_vm.btnInProgress)?_c('b-button',{staticClass:"mt-4",attrs:{"size":"lg","variant":"secondary"}},[_c('i',{staticClass:"fa fa-sign-in"}),_vm._v(" Installation en cours ... ")]):_vm._e()],1)],1)],1):_vm._e(),(_vm.registerSuccess)?_c('div',[_vm._m(0),_c('h3',[_vm._v("Votre compte Manageo est créé.")]),_c('p',{staticStyle:{"font-size":"15px"}},[_vm._v(" Nous venons de vous envoyer un e-mail de validation à votre adresse "),_c('b',[_vm._v(" "+_vm._s(_vm.registerSuccessEmail)+" ")]),_vm._v(". Il est probablement déjà dans votre boîte de réception. ")]),_c('p',{staticStyle:{"font-size":"15px"}},[_vm._v("A bientôt sur Manageo")]),_c('p',[_c('a',{staticClass:"btn host btn-lg btn-success",staticStyle:{"font-size":"20px"},attrs:{"href":_vm.registerSuccessUrl,"target":"_blank"}},[_c('i',{staticClass:"fa fa-rocket"}),_vm._v(" Lancez l'application ")])])]):_vm._e(),(_vm.registerError)?_c('div',[_c('h2',[_vm._v("Une erreur s'est produite")]),_c('p',[_vm._v(" "+_vm._s(_vm.registerErrorMessage)+" ")]),_vm._m(1)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',[_c('strong',[_vm._v("Félicitations ! ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('a',{staticClass:"btn btn-lg btn-danger",attrs:{"href":""}},[_vm._v("Recommencer")])])}]

export { render, staticRenderFns }
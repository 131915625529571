<template lang="html">
  <section class="separator">
  </section>
</template>

<script lang="js">

  export default  {
    name: 'separator',
    props: [],
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
}

</script>

<style scoped lang="css">
.separator {
  border-bottom: 2px solid #efefef;
  margin: 30px 10%;
}
</style>

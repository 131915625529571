
<template lang="html">
  <section class="faq">
  <div class="container">
      <div class="text-center">
      <h2>FAQ</h2>
      </div>
      <div class="accordion" role="tablist">
          <b-card no-body class="mb-1"
          v-for="(item, index) in faq"
          :key="index">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block variant="default" v-b-toggle="'accordion-' + index" :aria-controls="'accordion-'+index">
              {{ index +1 }}. {{ item.question }}
            </b-button>
          </b-card-header><b-collapse :id="'accordion-'+index" visible accordion="my-accordion" role="tabpanel">
            <b-card-body v-html="item.answer">
            </b-card-body>
          </b-collapse>
        </b-card>
        </div>
    </div>
  </section>
</template>


<script lang="js">

import faq from "../data/faq" ;

  export default  {
    name: 'faq',
    props: [],
    mounted () {

    },
    data () {
      return {
        faq : faq
      }
    },
}
</script>

<style scoped lang="css">
.card-body {
  line-height: 20px;
}
.card-header button {
  font-size: 18px;
  text-transform: uppercase;
}
</style>

<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      page: null,
    };
  },
  created() {
    // console.log(this.$router.currentRoute.meta.title);
    document.title = this.$router.currentRoute.meta.title;
  },
};
</script>

<style>
</style>

var plans = [
  {
    name: "Starter",
    price: {
      MAD: 0,
      XOF: 0,
      USD: 0,
    },
    features: [
      "CRM (limité à 50 clients)",
      "Ventes (limité à 20 factures par an)",
      "Produits & Services",
      "Trésorerie",
      "1 administrateur",
    ],
  },
  {
    name: "Services & Négoce",
    price: {
      MAD: 5400,
      XOF: 320000,
      USD: 540,
    },
    features: [
      "CRM",
      "Ventes",
      "Achats",
      "Produits & Services",
      "Trésorerie",
      "1 administrateur",
      "1 utilisateur",
    ],
  },
  {
    name: "Distribution",
    price: {
      MAD: 7200,
      XOF: 430000,
      USD: 720,
    },
    features: [
      "CRM",
      "Ventes",
      "Achats",
      "Produits & Services",
      "Trésorerie",
      "Stock",
      "1 administrateur",
      "1 utilisateur",
    ],
  },
  {
    name: "Com & Events",
    price: {
      MAD: 7800,
      XOF: 480000,
      USD: 780,
    },
    features: [
      "CRM",
      "Ventes",
      "Achats",
      "Produits & Services",
      "Trésorerie",
      "Affaires",
      "1 administrateur",
      "1 utilisateur",
    ],
  },
  {
    name: "Production",
    price: {
      MAD: 9000,
      XOF: 550000,
      USD: 900,
    },
    features: [
      "CRM",
      "Ventes",
      "Achats",
      "Produits & Services",
      "Trésorerie",
      "Production",
      "1 administrateur",
      "1 utilisateur",
    ],
  },
];

export default plans;

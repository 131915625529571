<template lang="html">

  <section class="arguments">
      <div class="services-block text-center grey-block">
        <div class="container">
          <h2>Un outil <strong>fiable</strong></h2>
          <h4>
            Connectez-vous depuis n'importe où sur PC ou depuis votre tablette.
          </h4>
          <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12 item">
              <i class="pi pi-lock"></i>
              <h3>Sécurité</h3>
              <p>
                Votre sécurité est notre priorité. Les connexions sont faites
                avec cryptage SSL 256-bit. Vos données sensibles sont protégées
                par des cryptages extrêmement fiables. Vous décidez qui de vos
                employés a accès à quelle donnée.
              </p>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12 item">
              <i class="pi pi-cloud"></i>
              <h3>Sauvegardes quotidiennes</h3>
              <p>
                Nos sauvegardes sont automatisées et quotidiennes afin d'éviter
                toute perte de données financières. Vos données sont plus en
                sécurité chez nous que sur votre propre ordinateur.
              </p>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12 item">
              <i class="pi pi-list"></i>
              <h3>Disponible 24h/24 7j/7</h3>
              <p>
                Manageo est un logiciel en ligne et ne nécessite aucune
                installation. Vous pouvez y accéder sur Windows, Mac, Linux,
                IPad, tablettes ou tout appareil équipé d'internet.
              </p>
            </div>
          </div>
        </div>

        <p class="text-center">
          <a href="#form" class="btn btn-lg btn-info">
            Demandez un rendez-vous
          </a>
        </p>
      </div>
  </section>

</template>

<script lang="js">

  export default  {
    name: 'arguments',
    props: [],
    mounted () {

    },
    data () {
      return {

      }
    },
}
</script>

<style scoped lang="css">
.arguments {
}
.services-block .item {
  border-right: solid 1px #e8e7e7;
  padding-top: 9px;
}
.services-block i.pi {
  background: #1a8ccd;
  font-size: 38px;
  padding: 35px 0 0;
  color: #fff;
  width: 99px;
  height: 99px;
  line-height: 1;
  border-radius: 50%;
  position: relative;
  margin-bottom: 29px;
}
</style>

<template lang="html">
  <div class="page">
    <my-header></my-header>
    
    <Separator />

    <section class="page">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h2>Logiciel de <strong>facturation</strong></h2>
            <p>
              Manageo est un logiciel de gestion commerciale dans le Cloud,
              intuitif, complet et entièrement modulable en fonction de vos
              besoins (clients, fournisseurs, ventes, achats, catalogue,
              trésorerie, stock...)
            </p>
            <p>
              <img src="@/assets/img/estimates.png" width="100%" border="0" />
            </p>
          </div>
          <div class="col-md-6" id="form">
            <Register partnerid="72"></Register>
          </div>
        </div>
      </div>

      <Features :color="color" />

      <Pricing currency="XOF" :color="color" />

      <Numbers :color="color" />

      <Testimonials :color="color" />

      <Arguments :color="color" />

      <Faq />

    </section>
    <my-footer></my-footer>
  </div>
</template>

<script lang="js">

import MyHeader from "./Header";
import Features from "../../components/Features";
import Register from "../../components/Register";
import Pricing from "../../components/Pricing";
import Testimonials from "../../components/Testimonials";
import Numbers from "../../components/Numbers";
import Separator from "../../components/Separator.vue";
import MyFooter from "../../components/Footer";
import Arguments from "../../components/Arguments";
import Faq from "../../components/Faq";

  export default  {
    name: 'page',
    components: {
      MyHeader,
      MyFooter,
      Features,
      Register,
      Pricing,
      Testimonials,
      Separator,
      Numbers,
      Arguments,
      Faq,
    },
    data() {
      return {
        color : "#e14d43"
      }
    },
    props: [],
}
</script>

<style scoped lang="css">
>>> h1 strong {
  color: #006699 !important;
}
>>> strong {
  color: #006699 !important;
}
>>> h2 strong {
  color: #006699 !important;
}
>>> section.pricing h4.card-title {
  background-color: #006699 !important;
}
>>> section.numbers span {
  color: #006699 !important;
}
>>> section.features i.pi {
  color: #006699 !important;
}
>>> section.arguments i.pi {
  background-color: #006699 !important;
}
</style>
